<template>
	<vvo v-slot="v">
		<div>
			<header-box :title="`${$t('코드 관리')} > ${$t('단위 코드')} > ${$t('소분류 코드')}`" />
			<div class="col-xl-12">
				<hr class="mt-3 mb-3" />

				<div class="row">
					<search-box :ref="'search'" @search:data="onSearch" @insertRow="insertRow" @save="onValidate(v, save, selectedRow)"
						@remove="remove" />
				</div>

				<div class="bg-white col-6" :style="{ padding: 0, minWidth: '600px' }">
					<grid-box ref="codeDetail" :data="items" @reSearch="reSearch" @getSelectedRow="getSelectedRow" />
				</div>
			</div>
		</div>
	</vvo>
</template>

<script>
	import SearchBox from "./SmallSearchBox.vue";
	import GridBox from "./UnitSmallCodeTable.vue";
	import HeaderBox from "@/views/component/headerBox/sys/Header.vue";
	import backEndApi from "@src/api/backEndApi";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		name: "PointMgmt",
		props: [],
		components: {
			SearchBox,
			GridBox,
			HeaderBox,
		},
		watch: {},

		data() {
			return {
				items: null,
				selectedRow: [],
				searchUnitLargeCode: "",
				searchLargeCode: "",
				notRemovingNewRow: [],
			};
		},
		created() {
			console.log("point management pointMgmt");
		},
		mounted() {},
		methods: {
			getSelectedRow(data) {
				this.selectedRow = data;
			},
			onSearch({ result, unitLargeCode }) {
				if (this.items !== null) {
					for (let i = 0; i < result.length; i++) {
            console.log(result[i]);
						let existItem = this.items.find((v) => v.unitSmallCode === result[i].unitSmallCode);
            console.log(existItem);
						if (existItem) {
							result[i].checked = existItem.checked;
						} else {
							result[i].checked = false;
						}
					}
				} else {
					result = result.map((v) => {
						v.checked = false;
						return v;
					});
				}

        console.log(this.items);

				this.items = result;
				this.items = [...this.items, ...this.notRemovingNewRow];
				this.searchLargeCode = unitLargeCode;
				this.notRemovingNewRow = [];
			},
			insertRow() {
				let newIndex = Math.max(
					...this.items.map((o) => {
						if (o.newIndex) return o.newIndex;
						else return 0;
					})
				);

				if (isNaN(newIndex)) newIndex = this.items.length;

				newIndex = newIndex + 1;

				this.items.push({
					isNew: true,
					newIndex: newIndex,
					checked: true,
					unitLargeCode: "",
					unitSmallCode: "",
					unitSmallCodeName: "",
					codeDesc: "",
					enabled: "",
				});
			},
			newModal() {
				console.log("new modal");
				this.$refs.newModal.show();
			},
			async save() {
				console.log(this.selectedRow);
				if (this.selectedRow.length === 0) {
					this.alertNoti(popupMessages.CODE_REGIST_FAIL_POPUP_MESSAGE);
					return;
				}
				// validation check
				for (let i = 0; i < this.selectedRow.length; i++) {
					const data = this.selectedRow[i];

					// if (data.unitSmallCode === "") {
					// 	this.alertNoti(popupMessages.CODE_SMALL_CODE_REQUIRED_VALID_POPUP_MESSAGE);
					// 	return;
					// }
					// if (data.unitSmallCodeName === "") {
					// 	this.alertNoti(popupMessages.CODE_SMALL_CODE_NAME_REQUIRED_VALID_POPUP_MESSAGE);
					// 	return;
					// }
					if (data.enabled === "") {
						this.alertWarning(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
						return;
					}
				}

				for (let i = 0; i < this.items.length; i++) {
					if (this.items[i].isNew === true && this.selectedRow.findIndex((v) => v.newIndex === this.items[i].newIndex) === -1) {
						this.notRemovingNewRow.push(this.items[i]);
					}
				}

				this.selectedRow.forEach((data) => {
					if (data.isNew) delete data.isNew;
					if (data.newIndex) delete data.newIndex;
					if (data.unitLargeCode) delete data.unitLargeCode;
					if (data.checked) delete data.checked;
				});

				let result;
				let saveCount = 0;

				let sendData = {
					unitLargeCode: this.searchLargeCode,
					unitSmallCodeList: this.selectedRow,
				};

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					result = await backEndApi.unitCode.saveUnitSmallCode(sendData);
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
					return;
				}

				const countString = result.data.message.split("건이");
				saveCount += Number(countString[0]);
				this.resetValue();

				this.alertNoti(saveCount + popupMessages.CODE_REGIST_POPUP_MESSAGE());
				this.reSearch();

				// const result = await backEndApi.unitCode.saveUnitSmallCode(sendData);

				// if(result.data.errno === 100){
				//   this.alertNoti(result.data.message);
				// } else if(result.data.errno === 109){
				//   this.alertNoti(result.data.error);
				// } else {
				//   this.alertNoti(result.data.message);
				//   this.reSearch();

				// }
			},
			async remove() {
				if (this.selectedRow.length <= 0) {
					this.alertNoti(popupMessages.COMMON_REMOVE_NO_DATA_POPUP_MESSAGE);
					return;
				}

				let removeCount = 0;

				for (let i = 0; i < this.selectedRow.length; i++) {
					let removeCode = this.selectedRow[i];

					if (removeCode.isNew === true) {
						let itemIndex = this.items.findIndex((v) => v.newIndex === removeCode.newIndex);
						this.items.splice(itemIndex, 1);
						this.selectedRow.splice(i, 1);
						removeCount++;
						i--;
					}
				}
				for (let i = 0; i < this.items.length; i++) {
					if (this.items[i].isNew === true) {
						this.notRemovingNewRow.push(this.items[i]);
					}
				}

				const data = this.selectedRow.map((data) => data.unitSmallCode);

				if (data.length > 0) {
					const params = { unitLargeCode: this.searchLargeCode, unitSmallCode: data };

					// 이전 리비전과 소스 에서 아래와 같이 변경함.
					/*           
					↓↓↓↓↓
				*/
					try {
						const result = await backEndApi.unitCode.removeUnitSmallCode(params);
						this.alertNoti(removeCount + Number(result.data) + popupMessages.CODE_DELETE_SUCCESS_POPUP_MESSAGE());
						this.$refs.codeDetail.resetRowSelect();
						this.reSearch();
					} catch (err) {
						new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
					}
				} else {
					this.alertNoti(removeCount + popupMessages.CODE_DELETE_SUCCESS_POPUP_MESSAGE());
					this.$refs.codeDetail.resetRowSelect();
					this.reSearch();
				}
			},

			hide() {
				this.$refs.newModal.hide();
			},
			reSearch() {
				this.$refs.search.reSearch();
			},
			resetValue() {
				this.$refs.codeDetail.selectedRow = [];
				this.selectedRow = [];
			},
		},
	};
</script>

<style scoped></style>
